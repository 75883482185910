<template>
  <div class="deposits">
    <div class="left">
      <div class="title">Способ вывода</div>
      <Methods :meta="meta" :method="method" @setMethod="changeMethod" />
    </div>

    <form class="right" @submit.prevent="withdraw">
      <Alert />

      <CurrentMethod :method="method" />

      <LineDown />

      <div class="title">Введите номер кошелька</div>
      <input
        type="text"
        required
        placeholder="+XXXXXXXXXX"
        v-model="purse"
        class="wallet-input"
      />

      <LineDown />

      <div class="inputs">
        <div class="flex">
          <div class="group">
            <label>Сумма вывода</label>
            <div class="input">
              <img
                alt="amount"
                src="@/assets/icons/profile/payment-coins.svg"
              />
              <input type="number" required v-model="amount" />
            </div>
          </div>

          <div class="group">
            <label>Комиссия</label>
            <div class="input">
              <img
                alt="amount"
                src="@/assets/icons/profile/payment-coins.svg"
              />
              <input
                type="text"
                disabled
                :value="(amount * (method.commission / 100)).toFixed(2)"
              />
            </div>
          </div>
        </div>

        <button>Создать заявку</button>
      </div>

      <Messages />
    </form>
  </div>
</template>

<script>
import Messages from "@/containers/Payments/Messages";
import LineDown from "@/components/Payments/Line";
import Methods from "@/containers/Payments/Methods";
import Alert from "@/components/Payments/Alert";
import CurrentMethod from "@/components/Payments/CurrentMethod";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      meta: [],
      modalShow: false,
      transfer: {},
      method: {
        max: 1500000,
        min: 1000,
        name: "qiwi",
      },
      purse: "",
      amount: 100,
    };
  },
  components: {
    Messages,
    LineDown,
    Methods,
    Alert,
    CurrentMethod,
  },
  computed: mapGetters("user", ["convertAmount", "getBalance"]),
  async created() {
    try {
      const { data } = await this.$http.get("wallet/withdraw/meta");
      this.meta = data.response;
      this.method = this.meta[0];
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
  methods: {
    changeMethod(id) {
      this.method = this.meta[id];
    },
    async withdraw() {
      try {
        const { data } = await this.$http.post("wallet/withdraw/create", {
          currency: this.getBalance.active,
          amount: this.convertAmount(
            this.amount,
            this.getBalance.active,
            false
          ),
          purse: this.purse,
          method: this.method.name,
        });

        if (data.error) return this.$toast.error(data.error);

        const { message, balances } = data.response;
        this.$toast.success(message);
        this.$store.commit("user/balance", balances);
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.deposits {
  display: flex;
  .left {
    border-right: 1px solid #f3f5fd;
    width: 412px;
    .title {
      margin-bottom: 15px;
      color: #3a3e46;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .right {
    padding-left: 20px;
    width: calc(100% - 412px);

    .title {
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
    }

    .wallet-input {
      border: 1px solid #e2e5f1;
      border-radius: 8px;
      height: 60px;
      padding: 0 15px;
      font-weight: 500;
      font-size: 18px;
      width: calc(100% - 10px);
    }

    .inputs {
      border: 1px solid #f5f6fa;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 8px;
      .flex {
        display: flex;
        justify-content: space-between;
        .group {
          margin-bottom: 20px;
          width: calc(50% - 10px);
          label {
            display: block;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            margin-bottom: 20px;
          }
          .input {
            border: 1px solid #e2e5f1;
            border-radius: 8px;
            height: 60px;
            display: flex;
            padding: 0 15px;
            align-items: center;
            img {
              margin-right: 10px;
            }
            input {
              font-weight: 500;
              font-size: 18px;
              background-color: transparent;
              width: calc(100% - 10px);
            }
          }
        }
        @media (max-width: 768px) {
          flex-wrap: wrap;
          .group {
            width: 100%;
          }
        }
      }

      button {
        background: #0085ff;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        margin: 0 auto;
        width: 300px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .left {
      width: 100%;
      border-right: none;
    }
    .right {
      width: 100%;
      padding-left: 0;
    }
  }
}
</style>
